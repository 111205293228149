<template>
  <div class="page">
    <div
      class="back-link"
      @click="$router.push({ name: 'Accounts' })"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ capitalizeFirstLetter($t('words.accountsManagement')) }}
      </span>
    </div>
    <div class="top">
      <b-row>
        <b-col class="left-col">
          <span>
            <img
              src="@/assets/icons/sphere_planet.svg"
              alt="Icon sphere planet"
            />
            {{ spheresCount }} {{ $tc('words.organisationGroup', spheresCount) }}
          </span>
          <div
            class="add-btn"
            @click="$router.push({ name: 'AddSphere' })"
          >
            <b-icon-plus />
            {{ $t('spheres.add') }}
          </div>
        </b-col>
        <b-col class="right-col">

        </b-col>
      </b-row>
    </div>
    <SearchablePaginatedTable
      :name="'spheresListTable'"
      :loading="loadingTable"
      :searchable="true"
      :searchPlaceholder="$t('spheres.search')"
      :searchFunction="SEARCH_USERGROUPS_LIST"
      :routeTo="'SphereDetail'"
      :fields="fields"
      :rows="rows"
      :count="spheresCount"
      :defaultSorting="{
        sortedUp: false,
        sortedDown: true,
        sortedField: 'date_joined'
      }"
      :sortFunction="GET_USERGROUPS_SPHERES_LIST"
      :selectable="false"
      @sorted="setTableSorted"
      @change-page="changeTablePage"
      @delete="openDeleteModal"
    />
  </div>
</template>

<script>
import SearchablePaginatedTable from '@/components/SearchablePaginatedTable/Layout.vue';

import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'Spheres',

  components: {
    SearchablePaginatedTable
  },

  data() {
    return {
      loadingTable: false,
      tableSorted: {
        direction: '',
        field: 'display_name'
      },
      sphereToDelete: null
    };
  },

  computed: {
    ...mapState('table', [
      'currentTables'
    ]),
    ...mapState('usergroups', [
      'spheres',
      'spheresCount',
      'isSpheresSearched'
    ]),
    fields() {
      return [
        {
          key: this.$t('spheres.table.fields.sphere'),
          apiName: 'display_name',
          sortable: true,
          width: '40%',
          centered: false
        },
        {
          key: this.$t('spheres.table.fields.creationDate'),
          apiName: 'creation_date',
          sortable: true,
          width: '',
          centered: true
        },
        {
          key: this.$t('spheres.table.fields.organisationsCount'),
          sortable: false,
          width: '',
          centered: true
        },
        {
          key: this.$t('spheres.table.fields.delete'),
          sortable: false,
          width: '',
          centered: true
        }
      ]
    },
    rows() {
      return this.spheres.map(el => {
        return {
          id: el.id,
          [this.$t('spheres.table.fields.sphere')]: el.display_name,
          [this.$t('spheres.table.fields.creationDate')]: new Date(el.creation_date).toLocaleString('fr-FR', {year: "numeric", month: "long", day: "numeric"}),
          [this.$t('spheres.table.fields.organisationsCount')]: el.children.length
        }
      });
    }
  },

  created() {
    this.SET_TABLE({
      name: 'spheresListTable',
      table: {
        loading: false,
        currentPage: this.currentTables.find(t => t.name === 'spheresListTable') ?
          this.currentTables.find(t => t.name === 'spheresListTable').currentPage :
          1,
        isTableSearched: false,
        searchQuery: null
      }
    });
  },

  mounted() {
    if (this.spheres.length === 0 || this.isSpheresSearched) {
      this.getData();
    }

    // Watch modal closing event
    this.$root.$on('bv::modal::hide', () => {
      this.deletingSphere = false
    });
  },

  methods: {
    ...mapMutations('table', [
      'SET_TABLE'
    ]),
    ...mapMutations('modal', [
      'OPEN_MODAL',
    ]),
    ...mapActions('usergroups', [
      'GET_USERGROUPS_SPHERES_LIST',
      'SEARCH_USERGROUPS_LIST',
      'DELETE_USERGROUP'
    ]),

    getData() {
      this.loadingTable = true;
      this.GET_USERGROUPS_SPHERES_LIST({...this.tableSorted})
        .then(() => {
          this.loadingTable = false;
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    openDeleteModal(e) {
      this.sphereToDelete = e;
      this.OPEN_MODAL({
        modal: 'confirmation',
        open: true,
        title: this.$t('modals.deleteSphere.title'),
        content: this.$t('modals.deleteSphere.content', {
          sphere: this.sphereToDelete[this.$t('spheres.table.fields.sphere')]
        }),
        trigger: this.deleteSphere
      });
    },

    deleteSphere() {
      this.loadingTable = true;
      this.DELETE_USERGROUP({
        id: this.sphereToDelete.id,
        usergroupType: 'sphere'
      })
        .then(() => {
          this.getData();
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    changeTablePage(e) {
      this.loadingTable = true;
      this.GET_USERGROUPS_SPHERES_LIST({
        ...this.tableSorted,
        page: e
      })
        .then(() => {
          this.loadingTable = false;
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    setTableSorted(e) {
      this.tableSorted = e;
    },
  }
}
</script>
